<template>
  <div class="flex flex-col min-h-screen overflow-hidden">
    <!-- Site header -->
    <AccountHeader />

    <!-- Page content -->
    <main class="grow mb-20">
      <section class="mb-20">
        <div class="max-w-6xl mx-auto px-4 sm:px-6">
          <div class="pt-32 pb-10 md:pt-40 md:pb-16">
            <form @submit.prevent="request" v-if="isAuthenticated">
              <div class="flex justify-center mb-4">
                <h2 class="text-xl text-white">
                  {{ $t("account.deletion.title") }}
                </h2>
              </div>
              <div class="flex justify-center mb-16">
                <h2 class="text-base text-gray-400 w-1/2 text-center">
                  {{
                    $t("account.deletion.intro", {
                      name: details?.Name,
                    })
                  }}
                </h2>
              </div>
              <div class="flex justify-center">
                <textarea
                  type="textarea"
                  class="form-input text-gray-100 w-1/2 h-32"
                  :placeholder="$t('account.deletion.reason.placeholder')"
                  :aria-label="$t('account.deletion.reason.label')"
                  v-model="reason"
                ></textarea>
              </div>
              <div class="flex justify-center mt-16">
                <input
                  type="checkbox"
                  class="form-checkbox mt-1"
                  v-model="ackowledged"
                />
                <div class="ml-3 text-gray-400">
                  {{ $t("account.deletion.confirmation.label") }}
                </div>
              </div>
              <div class="flex justify-center mt-8">
                <button
                  type="submit"
                  class="btn text-white bg-manypenny-primary-light hover:bg-manypenny-primary-dark shrink-0"
                >
                  {{ $t("account.deletion.button.text") }}
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>
    </main>

    <!-- Site footer -->
    <PageFooter />
  </div>
</template>

<script>
import { ref } from "vue";
import { useAuth0 } from "@auth0/auth0-vue";
import { storeToRefs } from "pinia";
import { toast } from "vue3-toastify";

import { submitForm } from "../services/formspree";
import { useUserStore } from "../stores/user";

import AccountHeader from "../partials/AccountHeader.vue";
import PageFooter from "../partials/PageFooter.vue";

export default {
  name: "AccountDeletion",
  components: {
    AccountHeader,
    PageFooter,
  },
  setup() {
    const { isAuthenticated } = useAuth0();
    const userStore = useUserStore();

    const { details } = storeToRefs(userStore);

    const ackowledged = ref(false);
    const reason = ref("");

    return {
      details,
      ackowledged,
      reason,
      isAuthenticated,
      userStore,
    };
  },
  methods: {
    async request() {
      if (!this.ackowledged) {
        toast.warning(this.$t("account.deletion.warning"), {
          autoClose: 5000,
        });
        return;
      }
      try {
        const email = this.details?.Email;
        const message = this.reason;
        const name = this.details?.Name;
        const priority = "high";
        const subject = "Account Deletion Request for {{ name }}";
        const tags = "account-deletion";
        const language = this.$i18n.locale;
        await submitForm("xyyqajlk", {
          email,
          message,
          name,
          priority,
          subject,
          tags,
          language,
        });
        toast.success(this.$t("account.deletion.success"), {
          autoClose: 5000,
          onClose: () => {
            this.$router.push("/account");
          },
        });
      } catch (err) {
        toast.error(this.$t("account.deletion.error"), {
          autoClose: 10000,
        });
      }
    },
  },
};
</script>
