<template>
  <div class="flex flex-col min-h-screen overflow-hidden">
    <!-- Site header -->
    <PageHeader />

    <!-- Page content -->
    <main class="grow">
      <!-- Page illustration -->
      <div
        class="relative max-w-6xl mx-auto h-0 pointer-events-none -z-1"
        aria-hidden="true"
      >
        <PageIllustration />
      </div>

      <!-- Page sections -->
      <HeroHome />
      <Stats />
      <ChainLogos
        v-if="!OSName.startsWith('Android') && !OSName.startsWith('iOS')"
      />
      <StoresMap v-if="hasWebGL()" />
      <Carousel />
      <!-- <PricingTables /> -->
      <FAQs />
      <Cta />
    </main>

    <!-- Site footer -->
    <PageFooter />
    <FloatingBanner />
  </div>
</template>

<script>
import UAParser from "ua-parser-js";

import Carousel from "./../partials/Carousel.vue";
import ChainLogos from "./../partials/ChainLogos.vue";
import Cta from "./../partials/Cta.vue";
import FAQs from "./../partials/FAQs.vue";
import HeroHome from "./../partials/HeroHome.vue";
import PageFooter from "./../partials/PageFooter.vue";
import PageHeader from "./../partials/Header.vue";
import PageIllustration from "./../partials/PageIllustration.vue";
// import PricingTables from "./../partials/PricingTables.vue";
import Stats from "./../partials/Stats.vue";
import StoresMap from "./../partials/StoresMap.vue";
import FloatingBanner from "../partials/FloatingBanner.vue";

const parser = new UAParser();
let osName = "";

try {
  osName = parser.getOS().name;
} catch (err) {
  console.log("Couldn't determine OS name");
}

const hasWebGL = () => {
  try {
    const canvas = document.createElement("canvas");
    return (
      !!window.WebGLRenderingContext &&
      (canvas.getContext("webgl") || canvas.getContext("experimental-webgl"))
    );
  } catch (e) {
    console.log("Browser does not support WebGL");
    return false;
  }
};

export default {
  name: "HomePage",
  components: {
    Carousel,
    ChainLogos,
    Cta,
    FAQs,
    FloatingBanner,
    HeroHome,
    PageFooter,
    PageHeader,
    PageIllustration,
    // PricingTables,
    Stats,
    StoresMap,
  },
  setup() {
    return {
      hasWebGL,
      OSName: osName,
    };
  },
};
</script>
