<template>
  <section class="mt-10 mb-10">
    <div class="max-w-6xl mx-auto px-4 sm:px-6">
      <!-- CTA box -->
      <div class="dark relative bg-gray-800 py-10 px-8 md:py-16 md:px-12">
        <!-- Background illustration -->
        <div
          class="absolute inset-0 left-auto pointer-events-none"
          aria-hidden="true"
        >
          <svg
            class="h-full"
            width="400"
            height="232"
            viewBox="0 0 400 232"
            xmlns="http://www.w3.org/2000/svg"
          >
            <defs>
              <radialGradient
                cx="50%"
                cy="50%"
                fx="50%"
                fy="50%"
                r="39.386%"
                id="box-gr-a"
              >
                <stop stop-color="#667EEA" offset="0%" />
                <stop stop-color="#667EEA" stop-opacity="0" offset="100%" />
              </radialGradient>
              <radialGradient
                cx="50%"
                cy="50%"
                fx="50%"
                fy="50%"
                r="39.386%"
                id="box-gr-b"
              >
                <stop stop-color="#3ABAB4" offset="0%" />
                <stop stop-color="#3ABAB4" stop-opacity="0" offset="100%" />
              </radialGradient>
            </defs>
            <g transform="translate(-85 -369)" fill="none" fill-rule="evenodd">
              <circle
                fill-opacity=".16"
                fill="url(#box-gr-a)"
                cx="413"
                cy="688"
                r="240"
              />
              <circle
                fill-opacity=".24"
                fill="url(#box-gr-b)"
                cx="400"
                cy="400"
                r="400"
              />
            </g>
          </svg>
        </div>

        <div
          class="relative flex flex-col lg:flex-row justify-between items-center"
        >
          <!-- CTA content -->
          <div class="mb-6 lg:mr-16 lg:mb-0 text-center lg:text-left lg:w-1/2">
            <h3 class="h3 font-red-hat-display text-gray-100">
              {{ $t("cta.title") }}
            </h3>
          </div>

          <!-- CTA form -->
          <form class="w-full lg:w-1/2" @submit.prevent="submitForm">
            <div
              class="flex flex-col sm:flex-row justify-center max-w-xs mx-auto sm:max-w-md lg:max-w-none"
            >
              <input
                v-model="email"
                type="email"
                class="form-input text-gray-100 w-full mb-2 sm:mb-0 sm:mr-2"
                :placeholder="$t('cta.form.email.placeholder')"
                :aria-label="$t('cta.form.email.label')"
              />
              <button
                class="btn text-white bg-manypenny-primary-light hover:bg-manypenny-primary-dark shrink-0"
              >
                {{ $t("cta.form.button.text") }}
              </button>
            </div>
            <!-- Success message -->
            <p
              v-if="isSubscribed"
              class="text-center lg:text-left lg:absolute mt-2 opacity-75 text-sm"
            >
              {{ $t("cta.form.messages.success") }}
            </p>
            <!-- Conflict message -->
            <p
              v-if="conflictMessage"
              class="text-center lg:text-left lg:absolute mt-2 text-blue-500 text-sm"
            >
              {{ conflictMessage }}
            </p>
            <!-- Error message -->
            <p
              v-if="errorMessage"
              class="text-center lg:text-left lg:absolute mt-2 text-red-500 text-sm"
            >
              {{ errorMessage }}
            </p>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { subscribeToNewsletter } from "../services/api";

export default {
  name: "CTA",
  data() {
    return {
      conflictMessage: null,
      email: "",
      errorMessage: null,
      isSubscribed: false,
    };
  },
  methods: {
    async submitForm() {
      try {
        const response = await subscribeToNewsletter(this.email);
        // 3 possible responses: 200, 409 or 422
        if (response.status === 200) {
          this.conflictMessage = null;
          this.errorMessage = null;
          this.isSubscribed = true;
          return;
        } else if (response.status === 409) {
          this.conflictMessage = this.$t("cta.form.messages.conflict");
          this.errorMessage = null;
          return;
        } else if (response.status === 422) {
          this.conflictMessage = null;
          this.errorMessage = this.$t("cta.form.messages.error");
          return;
        }
      } catch (error) {
        this.errorMessage = this.$t("cta.form.messages.unknown");
      }
    },
  },
};
</script>
