<template>
  <router-view />
</template>

<script>
import * as Sentry from "@sentry/vue";
import AOS from "aos";
import posthog from "posthog-js";
import { useAuth0 } from "@auth0/auth0-vue";

import { getHealthStatus, reverseGeocode } from "./services/api";
import { getChainsManifest, getStoresManifest } from "./services/assets";
import { socket } from "./services/events";
import { getOfferings } from "./services/revenuecat";
import { getPrice, getProduct } from "./services/stripe";

import { useDataStore } from "./stores/data";
import { useGeolocationStore } from "./stores/geolocation";
import { useOfferingStore } from "./stores/offering";
import { useUserStore } from "./stores/user";

socket.on("connect", () => {
  console.log("Socket connected");
});

export default {
  setup() {
    const { isAuthenticated } = useAuth0();

    const dataStore = useDataStore();
    const geolocationStore = useGeolocationStore();
    const offeringStore = useOfferingStore();
    const userStore = useUserStore();

    return {
      dataStore,
      geolocationStore,
      isAuthenticated,
      offeringStore,
      userStore,
    };
  },
  async mounted() {
    AOS.init({
      once: true,
      disable: "phone",
      duration: 600,
      easing: "ease-out-sine",
    });
    // Offering
    const { current_offering_id, offerings } = await getOfferings();
    const offering = offerings.find(
      (o) => o.identifier === current_offering_id
    );
    this.offeringStore.offering = offering;
    const packages = offering.packages;
    await Promise.all(
      packages.map(async ({ identifier, platform_product_identifier }) => {
        const product = await getProduct(platform_product_identifier);
        const price = await getPrice(product.default_price);
        this.offeringStore.packages[identifier] = {
          product,
          price,
        };
      })
    );
    // Position
    const { position } = await getHealthStatus();
    this.geolocationStore.userPosition = String(position).split(",");
    if (
      this.geolocationStore.userPosition &&
      this.geolocationStore.userPosition.length === 2
    ) {
      try {
        const reverseGeocodeData = await reverseGeocode(
          this.geolocationStore.userPosition[1],
          this.geolocationStore.userPosition[0]
        );
        if (
          reverseGeocodeData &&
          reverseGeocodeData.address_components &&
          reverseGeocodeData.address_components.length > 0 &&
          reverseGeocodeData.address_components.find((component) =>
            component.types.includes("country")
          )?.short_name === "CA"
        ) {
          this.geolocationStore.reverse = reverseGeocodeData;
          this.geolocationStore.mapCenter = this.geolocationStore.userPosition;
          this.geolocationStore.mapZoom = 9;
        }
      } catch (err) {
        // *
      }
    }
    // Data
    try {
      this.dataStore.chains = await getChainsManifest();
    } catch (err) {
      // *
    }
    try {
      this.dataStore.stores = await getStoresManifest();
    } catch (err) {
      // *
    }
  },
  watch: {
    async isAuthenticated(newVal) {
      if (newVal) {
        await Promise.all([
          this.userStore.fetchDetails(),
          this.userStore.fetchSubscriberData(),
        ]);
        if (import.meta.env.PROD) {
          try {
            const { Email, Name, UUID } = this.userStore.details;
            // PostHog
            posthog.identify(UUID, {
              email: Email,
              name: Name,
            });
            // Sentry
            Sentry.setUser({
              email: Email,
              id: UUID,
              ip_address: "{{auto}}",
              username: Name,
            });
          } catch (err) {
            // *
          }
        }
      }
    },
  },
};
</script>
