import * as Sentry from "@sentry/vue";
import posthog from "posthog-js";
import { createApp } from "vue";
import { createI18n } from "vue-i18n";
import { createPinia } from "pinia";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import browserLang from "browser-lang";
import FloatingVue from "floating-vue";
import {
  plugin as UnleashPlugin,
  UnleashClient,
} from "@unleash/proxy-client-vue";

import { faHeart, faXmark } from "@fortawesome/pro-solid-svg-icons";

// Styles
import "aos/dist/aos.css";
import "mapbox-gl/dist/mapbox-gl.css";
import "vue3-toastify/dist/index.css";
import "floating-vue/dist/style.css";
import "./css/style.css";
// End Styles

import router from "./router";
import App from "./App.vue";

import auth0 from "./plugins/auth0";

import en from "./locales/en";
import fr from "./locales/fr";

const messages = {
  en,
  fr,
};

const language = browserLang({
  languages: Object.keys(messages),
  fallback: import.meta.env.VITE_LOCALE_FALLBACK,
});

const i18n = createI18n({
  fallbackLocale: import.meta.env.VITE_LOCALE_FALLBACK,
  globalInjection: true,
  legacy: false,
  locale: language || import.meta.env.VITE_LOCALE_DEFAULT,
  messages,
});

const app = createApp(App);

if (import.meta.env.PROD) {
  Sentry.init({
    app,
    attachProps: true,
    attachStacktrace: true,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
      // Sentry.browserProfilingIntegration(),
      Sentry.browserTracingIntegration({
        router,
      }),
      Sentry.replayIntegration(),
    ],
    // profilesSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0,
    replaysSessionSampleRate: 1.0,
    tracesSampleRate: 1.0,
  });
}

if (import.meta.env.PROD) {
  posthog.init(import.meta.env.VITE_POSTHOG_PROJECT_API_KEY, {
    api_host: import.meta.env.VITE_POSTHOG_INSTANCE_ADDRESS,
  });
}

const unleashClient = new UnleashClient({
  url: import.meta.env.VITE_UNLEASH_URL,
  clientKey: import.meta.env.VITE_UNLEASH_CLIENT_KEY,
  refreshInterval: 15,
  appName: import.meta.env.VITE_UNLEASH_APP_NAME,
});

library.add(faHeart, faXmark);

app.use(router);
app.use(createPinia());
app.use(i18n);
app.use(FloatingVue);

app.use(auth0);
app.use(UnleashPlugin, {
  unleashClient,
});

app.component("font-awesome-icon", FontAwesomeIcon);

app.mount("#app");
