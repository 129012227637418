import axios from "axios";
import { backOff } from "exponential-backoff";

const baseURL = import.meta.env.VITE_STRIPE_API_URL;
const apiKey = import.meta.env.VITE_STRIPE_API_KEY;

// Initialize axios with default config
const api = axios.create({
  baseURL,
  timeout: 15000,
});

api.interceptors.request.use(
  async (config) => {
    config.headers["Authorization"] = `Bearer ${apiKey}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// *

export const getPrice = async (id, options) => {
  try {
    const response = await backOff(() => api.get(`/prices/${id}`, options));
    return response.data;
  } catch (error) {
    throw new Error(`Could not get Price ${id}: ${error}`);
  }
};

export const getProduct = async (id, options) => {
  try {
    const response = await backOff(() => api.get(`/products/${id}`, options));
    return response.data;
  } catch (error) {
    throw new Error(`Could not get Product ${id}: ${error}`);
  }
};
