<template>
  <section class="border-t border-transparent dark:border-gray-800">
    <MapboxMap
      :access-token="mapboxToken"
      :attributionControl="false"
      :center="mapCenter"
      :interactive="false"
      :zoom="mapZoom"
      @mb-created="(mapInstance) => (map = mapInstance)"
      map-style="mapbox://styles/mapbox/dark-v11"
      projectionMode="mercator"
      style="height: 400px"
    >
      <MapboxLayer
        id="stores"
        :options="{
          type: 'circle',
          source: {
            type: 'geojson',
            data: 'https://assets.manypenny.ca/stores.geojson',
          },
          paint: {
            'circle-radius': 3,
            'circle-stroke-width': 0.5,
            'circle-color': '#204652',
            'circle-stroke-color': '#d19a52',
          },
        }"
      />
    </MapboxMap>
  </section>
</template>

<script>
import { MapboxLayer, MapboxMap } from "@studiometa/vue-mapbox-gl";
import { storeToRefs } from "pinia";

import { useGeolocationStore } from "../stores/geolocation";

const mapboxToken = import.meta.env.VITE_MAPBOX_TOKEN;

export default {
  name: "StoresMap",
  components: {
    MapboxLayer,
    MapboxMap,
  },
  setup() {
    const geolocationStore = useGeolocationStore();
    const { mapCenter, mapZoom } = storeToRefs(geolocationStore);
    return {
      geolocationStore,
      map: null,
      mapCenter,
      mapZoom,
      mapboxToken,
    };
  },
};
</script>
