<template>
  <section
    id="plans"
    class="relative bg-gray-900 border-t border-transparent dark:border-gray-800"
  >
    <div class="max-w-6xl mx-auto px-4 sm:px-6">
      <div class="py-12 md:py-20">
        <!-- Section header -->
        <div class="pb-12 md:pb-20 text-center">
          <h2 class="h2 font-red-hat-display">{{ $t("faqs.title") }}</h2>
        </div>

        <!-- Columns -->
        <div class="md:flex md:space-x-12 space-y-8 md:space-y-0">
          <!-- Column -->
          <div class="w-full md:w-1/2 space-y-8">
            <!-- Item -->
            <div class="space-y-2">
              <h4 class="text-xl font-red-hat-display font-bold">
                {{ $t("faqs.one.question") }}
              </h4>
              <p class="text-gray-400">
                {{ $t("faqs.one.answer") }}
              </p>
            </div>

            <!-- Item -->
            <div class="space-y-2">
              <h4 class="text-xl font-red-hat-display font-bold">
                {{ $t("faqs.two.question") }}
              </h4>
              <p class="text-gray-400">
                {{ $t("faqs.two.answer") }}
              </p>
            </div>
          </div>

          <!-- Column -->
          <div class="w-full md:w-1/2 space-y-8">
            <!-- Item -->
            <div class="space-y-2">
              <h4 class="text-xl font-red-hat-display font-bold">
                {{ $t("faqs.three.question") }}
              </h4>
              <p class="text-gray-400">
                {{ $t("faqs.three.answer") }}
              </p>
            </div>

            <!-- Item -->
            <div class="space-y-2">
              <h4 class="text-xl font-red-hat-display font-bold">
                {{ $t("faqs.six.question") }}
              </h4>
              <p class="text-gray-400">
                {{ $t("faqs.six.answer") }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "FAQs",
};
</script>
