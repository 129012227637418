import { createRouter, createWebHistory } from "vue-router";
import { authGuard } from "@auth0/auth0-vue";

import AccountDeletion from "./pages/AccountDeletion.vue";
import AccountPage from "./pages/AccountPage.vue";
import AuthCallback from "./pages/AuthCallback.vue";
import HomePage from "./pages/HomePage.vue";
import NotFound from "./pages/NotFound.vue";
import PortalPage from "./pages/PortalPage.vue";
import SubscribeCancel from "./pages/SubscribeCancel.vue";
import SubscribePage from "./pages/SubscribePage.vue";
import SubscribeSuccess from "./pages/SubscribeSuccess.vue";

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: "/",
      component: HomePage,
    },
    {
      path: "/account",
      component: AccountPage,
      beforeEnter: authGuard,
      props: true,
    },
    {
      path: "/account/deletion",
      component: AccountDeletion,
      beforeEnter: authGuard,
    },
    {
      path: "/auth/callback",
      component: AuthCallback,
    },
    {
      path: "/subscribe/cancel",
      component: SubscribeCancel,
      beforeEnter: authGuard,
    },
    {
      path: "/subscribe/success",
      component: SubscribeSuccess,
      beforeEnter: authGuard,
    },
    {
      path: "/subscribe",
      component: SubscribePage,
      beforeEnter: authGuard,
    },
    {
      path: "/subscription/portal",
      component: PortalPage,
      beforeEnter: authGuard,
    },
    {
      path: "/:pathMatch(.*)*",
      component: NotFound,
    },
  ],
});

export default router;
