export default {
  account: {
    button: {
      text: "Save",
    },
    deletion: {
      label: "Delete my account",
      link: "Click here to request account deletion",
      success: "Your account deletion request has been submitted.",
      error: "Oops, something went wrong. Please try again.",
      warning:
        "Please confirm that you understand your account and all associated data will be permanently deleted.",
      button: {
        text: "Send request",
      },
      confirmation: {
        label:
          "I understand that my account and all associated data will be permanently deleted.",
      },
      reason: {
        placeholder:
          "We're always looking to improve. If you're comfortable sharing, tell us why you're leaving.",
        label: "Reason for requesting account deletion",
      },
      title: "Request account deletion",
      intro:
        "We're sorry to see you go {name}. Your privacy and data security are important to us. If you wish to delete your account and remove all associated data, please fill out this form. We'll process your request as quickly as possible, ensuring all your information is securely and completely removed from our system.",
    },
    fullName: {
      cantUpdate:
        "Your name is managed by your identity provider (Apple or Google). To change your name, please update your profile with your identity provider.",
      label: "Full name",
      placeholder: "Enter your full name",
    },
    store: {
      app_store: {
        instructions:
          "To manage your subscription, open the App Store app on your device and tap your profile icon in the top right corner. Then, tap Subscriptions. In the list of subscriptions, find and tap Many Penny. There, you may change or cancel your subscription.",
      },
      play_store: {
        instructions:
          "To manage your subscription, open the Google Play Store app on your device and tap the ☰ (three horizontal lines) icon. Then, tap Subscriptions. In the list of subscriptions, find and tap Many Penny. There, you may change or cancel your subscription.",
      },
      promotional: {
        instructions:
          "Congrats! You've been awarded a free promotional subscription to Many Penny. Enjoy the premium experience on us. No need to manage anything, just dive in and have fun!",
      },
      stripe: {
        link: "Click here to manage your subscription",
      },
    },
    subscription: {
      label: "Your subscription",
      text: "Your are subscribed to the {plan} plan through {store}",
      expiry: "Your subscription is set to expire or renew on {date} at {time}",
    },
    upgrade: {
      text: {
        line1: "You're currently signed up for the free version of Many Penny.",
        line2:
          "Upgrade to the Standard plan to get access to all the features.",
      },
    },
  },
  carousel: {
    title: "Always updated, never outdated",
    subtitle:
      "Prices can fluctuate daily. With Many Penny, you get the latest pricing trends and the best deals, all without the headache of manual tracking. Trust us to keep you ahead of the curve, so you can focus on making smart choices.",
    photos: {
      one: {
        alt: "Man in a grocery aisle looking at cleaning products.",
        caption: "Making informed decisions with every glance.",
      },
      two: {
        alt: "Woman checking her phone while holding a grocery item.",
        caption: "Empower your shopping with real-time insights.",
      },
      three: {
        alt: "Man browsing on his phone in the vegetable section.",
        caption: "Navigate the freshest deals with ease.",
      },
      four: {
        alt: "Fashionable woman checking her phone in a grocery store.",
        caption: "Elegance meets efficiency in every shopping trip.",
      },
      five: {
        alt: "Businessman examining a drink bottle in a grocery aisle.",
        caption: "Choosing wisely, even on the go.",
      },
      six: {
        alt: "Man reading product details in a grocery store with price tags visible.",
        caption: "Every penny counts; every detail matters.",
      },
    },
    buttons: {
      next: "Next",
      previous: "Previous",
    },
  },
  cta: {
    title: "Subscribe to our newsletter and get the latest updates",
    form: {
      button: {
        text: "Subscribe",
      },
      email: {
        label: "Email address",
        placeholder: "Enter your email",
      },
      messages: {
        conflict: "You're already subscribed to our newsletter. Thanks!",
        error: "Please enter a valid email address.",
        success: "Thanks for subscribing! We'll be in touch.",
        unknown: "Oops, something went wrong. Please try again.",
      },
    },
  },
  dropdown: {
    logout: {
      text: "Logout",
    },
  },
  footer: {
    attributions: {
      apple:
        "Apple, the Apple logo and iPhone are trademarks of Apple Inc., registered in the U.S. and other countries. App Store is a service mark of Apple Inc., registered in the U.S. and other countries.",
      google:
        "Google Play and the Google Play logo are trademarks of Google LLC.",
      others:
        "Other product and company names mentioned herein may be trademarks of their respective companies.",
    },
    built: {
      pre: "Built with",
      post: "in Victoria, B.C., Canada.",
    },
    contact: {
      header: "Contact",
      affiliates: {
        address: "https://affiliates.manypenny.ca",
        label: "Affiliates",
      },
      email: {
        address: "hello{'@'}manypenny.ca",
        label: "hello(a)manypenny.ca",
      },
      press: {
        address: "https://newsroom.manypenny.ca",
        label: "Press",
      },
      phone: {
        uri: "sms:+17784002084",
        display: "(778) 400-2084",
      },
    },
    copyrights: {
      note: "All rights reserved.",
    },
    what: {
      header: "What we do",
      blog: "Blog",
      plans: "Plans and Features",
      chrome: {
        href: "https://chromewebstore.google.com/detail/many-penny/lnlbedbfpggddbdebdlknbjlibgcande",
        display: "Google Chrome Extension",
      },
    },
    support: {
      header: "Support",
      hc: "Help Center",
    },
    status: {
      header: "Status",
      status: "System Status",
    },
    terms: {
      acceptable: "Acceptable Use Policy",
      consent: "Consent Preferences",
      cookie: "Cookie Policy",
      disclaimer: "Disclaimer",
      donotsell: "Do Not Sell or Share My Personal information",
      eula: "EULA",
      privacy: "Privacy Policy",
      terms: "Terms and Conditions",
    },
  },
  header: {
    button: {
      text: "Get started",
      authenticated: "My account",
    },
  },
  hero: {
    title: "Smart Savings Beyond Flyers",
    subtitle:
      "Many Penny is your pocket price checker for real-time comparisons – skip the flyers, scan and save!",
    features: {
      one: "Instant price comparisons",
      two: "Effortless deal hunting",
      three: "Price drop alerts",
    },
  },
  logo: {
    alt: "Many Penny Logo",
  },
  mockup: {
    alt: "Many Penny Mobile Application Home Screen",
    home: "Home",
    yourFavouriteStores: "Your favourite stores",
    location: "Victoria, BC, Canada",
    seeAll: "See all",
    search: "Search",
    groceryList: "Grocery list",
    profile: "Profile",
    stores: {
      first: {
        chain: "Thrifty Foods",
        address: "1590 Fairfield Rd",
        distance: "1.2 km away",
      },
      second: {
        chain: "Real Canadian Superstore",
        address: "172 Wilson St",
        distance: "1.4 km away",
      },
      third: {
        chain: "Walmart",
        address: "2635 Quadra St",
        distance: "1.8 km away",
      },
    },
    distance: "{distance} km away",
    allCategories: "All categories",
    categories: {
      baby: "Baby",
      beauty: "Beauty",
      breadBakery: "Bread & Bakery",
      clothingShoes: "Clothing, Shoes & Accessories",
      frozenFood: "Frozen Food",
      fruitsVegetables: "Fruits & Vegetables",
      health: "Health",
      homeKitchen: "Home, Kitchen & Outdoor",
      personalCare: "Personal Care",
      pets: "Pets",
      seasonalHolidays: "Seasonal Holidays",
      snacksCandy: "Snacks & Candy",
    },
  },
  plans: {
    title: "Unleash Smart Shopping with Many Penny",
    subtitle:
      "Begin with our foundational features at no cost, and when you're ready, unlock a holistic shopping experience with our Standard plan.",
    manage: {
      button: {
        text: "Manage subscription",
      },
    },
    current: {
      text: "Your current plan",
    },
    plans: {
      all: {
        features: {
          header: "All plans include:",
          one: "Unified shopping experience: Say goodbye to juggling between sites or apps",
          two: "Continuously updated product listings",
          three: "Intuitive interface for a seamless journey",
          four: "Secure and reliable account management",
        },
      },
      free: {
        name: "Free",
        period: "forever",
        features: {
          one: "Convenient product search, including barcode scanning",
          two: "Browse products by category",
          three: "Uniform product categorization across chains",
          four: "Build your shopping lists",
          five: "Select and save your favorite stores",
        },
        button: {
          text: "Get started",
        },
      },
      standard: {
        name: "Standard",
        period: "monthly",
        trial: "2 weeks trial",
        features: {
          one: "View and compare product prices across stores",
          two: "Access to historical price trends",
          three: "Automatic shopping itinerary for the best deals",
          four: "Continuous lookout for optimal deals based on your lists",
        },
        button: {
          text: "Subscribe",
          upgrade: "Upgrade",
        },
      },
    },
  },
  stats: {
    products: {
      label: "Products",
    },
    prices: {
      label: "Prices",
    },
    promotions: {
      label: "Promotions",
    },
    stores: {
      label: "Stores",
    },
  },
  subscribe: {
    success: "Your subscription has been successfully updated.",
    cancel: "Your subscription checkout session was canceled.",
  },
  faqs: {
    title: "Frequently Asked Questions",
    one: {
      question: "What is Many Penny?",
      answer:
        "Many Penny is an innovative price comparison and alert app that aims to transform your shopping experience. The app provides real-time price comparisons across major Canadian chains, price drop alerts, personalized shopping lists, comprehensive price tracking, and more.",
    },
    two: {
      question: "How does Many Penny work?",
      answer:
        "After creating your account, Simply add items to your shopping list, and we'll instantly compare prices across various stores, saving you time and money. If a price drop occurs on an item you've favourited, we'll send you an immediate notification.",
    },
    three: {
      question: "Why choose Many Penny?",
      answer:
        "We offer a service that goes beyond traditional flyers and single-store apps. Our extensive database gives up-to-date pricing, ongoing promotions, and historical pricing data. We provide personalized shopping experiences, and we prioritize user privacy.",
    },
    four: {
      question: "How is Many Penny's revenue model different?",
      answer:
        "Unlike most apps that rely on product affiliate links to generate revenue, we operate on a subscription-based revenue model. This approach allows us to preserve our independence and provide you with unbiased pricing information.",
    },
    five: {
      question: "How do I subscribe to Many Penny?",
      answer:
        "Once you register with Many Penny, you'll receive a month-long preview period free of charge. After this period, if you decide to continue with our services, you can subscribe through various channels. Subscriptions can be made directly on our website, or through app marketplaces like the App Store for iOS users and the Google Play Store for Android users. Enjoy the versatility and convenience of managing your subscription where it suits you best.",
    },
    six: {
      question: "If I need help, how can I contact Many Penny?",
      answer:
        "If you require help or further information, please reach out to our support team at support(a)manypenny.ca. We're committed to ensuring your shopping experience is as smooth and enjoyable as possible.",
    },
  },
};
