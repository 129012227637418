import axios from "axios";
import { backOff } from "exponential-backoff";

const baseURL = import.meta.env.VITE_FORMSPREE_FORM_URL;

// Initialize axios with default config
const formspree = axios.create({
  baseURL,
  timeout: 15000,
});

// *

export const submitForm = async (id, data) => {
  try {
    const response = await backOff(() =>
      formspree.request(`/${id}`, {
        method: "POST",
        data,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
    );
    return response.data;
  } catch (error) {
    throw new Error(`Could not get Price ${id}: ${error}`);
  }
};
