<template>
  <header class="absolute w-full z-30">
    <div class="max-w-6xl mx-auto px-4 sm:px-6">
      <div class="flex items-center justify-between h-20">
        <!-- Site branding -->
        <div class="shrink-0 mr-5">
          <!-- Logo -->
          <router-link to="/" class="block" aria-label="Many Penny">
            <img
              class="h-8"
              src="@/images/manypenny-logo-typo.svg"
              :alt="$t('logo.alt')"
            />
          </router-link>
        </div>

        <!-- Desktop navigation -->
        <nav class="md:flex md:grow">
          <!-- Desktop menu links -->
          <ul class="flex grow flex-wrap items-center font-medium"></ul>
          <ul class="flex justify-end flex-wrap items-center">
            <DropdownMenu :title="details?.Name || ''">
              <li>
                <a
                  href="/logout"
                  class="text-sm text-gray-600 dark:text-gray-400 hover:text-manypenny-tertiary-dark dark:hover:text-manypenny-tertiary-dark flex py-2 px-4 leading-tight"
                  @click.prevent="signOut"
                  >{{ $t("dropdown.logout.text") }}</a
                >
              </li>
            </DropdownMenu>
          </ul>
        </nav>
      </div>
    </div>
  </header>
</template>

<script>
import { useAuth0 } from "@auth0/auth0-vue";
import { storeToRefs } from "pinia";

import DropdownMenu from "./../utils/DropdownMenu.vue";
import { useUserStore } from "../stores/user";

export default {
  name: "AccountHeader",
  components: {
    DropdownMenu,
  },
  data() {
    return {
      mobileNavOpen: false,
    };
  },
  setup() {
    const { isAuthenticated, logout } = useAuth0();
    const userStore = useUserStore();

    const { details } = storeToRefs(userStore);

    const signOut = () => {
      logout({
        returnTo: window.location.origin,
      });
    };

    return {
      isAuthenticated,
      signOut,
      details,
    };
  },
};
</script>
