<template>
  <header class="absolute w-full z-30">
    <div class="max-w-6xl mx-auto px-4 sm:px-6">
      <div class="flex items-center justify-between h-20">
        <!-- Site branding -->
        <div class="shrink-0 mr-5">
          <!-- Logo -->
          <router-link to="/" class="block" aria-label="Many Penny">
            <img
              class="h-8"
              src="@/images/manypenny-logo-typo.svg"
              :alt="$t('logo.alt')"
            />
          </router-link>
        </div>

        <!-- Desktop navigation -->
        <nav class="md:flex md:grow">
          <!-- Desktop menu links -->
          <ul class="flex grow flex-wrap items-center font-medium"></ul>

          <!-- Desktop CTA on the right -->
          <ul class="flex justify-end flex-wrap items-center">
            <li>
              <router-link
                to="/account"
                class="btn-sm text-white bg-manypenny-primary-light hover:bg-manypenny-primary-dark ml-6"
                >{{
                  isAuthenticated
                    ? $t("header.button.authenticated")
                    : $t("header.button.text")
                }}</router-link
              >
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </header>
</template>

<script>
import { useAuth0 } from "@auth0/auth0-vue";
import { storeToRefs } from "pinia";

import { useUserStore } from "../stores/user";

export default {
  name: "PageHeader",
  data() {
    return {
      mobileNavOpen: false,
      darkMode: this.handleLights(),
    };
  },
  setup() {
    const { isAuthenticated, logout } = useAuth0();
    const userStore = useUserStore();

    const { details: userDetails } = storeToRefs(userStore);

    const signOut = () => {
      logout({
        returnTo: window.location.origin,
      });
    };

    return {
      isAuthenticated,
      signOut,
      userDetails,
    };
  },
  methods: {
    clickOutside(e) {
      if (
        !this.mobileNavOpen ||
        this.$refs.mobileNav.contains(e.target) ||
        this.$refs.hamburger.contains(e.target)
      )
        return;
      this.mobileNavOpen = false;
    },
    keyPress() {
      if (!this.mobileNavOpen || event.keyCode !== 27) return;
      this.mobileNavOpen = false;
    },
    handleLights: function () {
      const dark = localStorage.getItem("dark-mode");
      if (dark === null) {
        return true;
      } else {
        return dark === "true";
      }
    },
  },
  watch: {
    darkMode() {
      localStorage.setItem("dark-mode", this.darkMode);
      if (this.darkMode) {
        document.documentElement.classList.add("dark");
      } else {
        document.documentElement.classList.remove("dark");
      }
    },
  },
  mounted() {
    document.addEventListener("click", this.clickOutside);
    document.addEventListener("keydown", this.keyPress);
  },
  beforeUnmount() {
    document.removeEventListener("click", this.clickOutside);
    document.removeEventListener("keydown", this.keyPress);
  },
};
</script>
