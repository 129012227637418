<template>
  <section class="relative">
    <div class="relative max-w-6xl mx-auto px-4 sm:px-6">
      <div class="pb-12 md:pb-20">
        <div class="grid grid-cols-2 gap-4 lg:gap-6 md:grid-cols-4 text-center">
          <!-- Loop through metrics and display them -->
          <div
            v-for="(metric, index) in metrics"
            :key="index"
            class="bg-white dark:bg-gray-800 py-8 px-1 shadow-2xl"
          >
            <div
              class="font-red-hat-display text-3xl font-black tracking-tighter mb-1"
            >
              {{ metric.value }}
            </div>
            <div class="text-gray-600 dark:text-gray-400">
              {{ metric.label }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import humanReadable from "human-readable-numbers";

import {
  getPricesMetrics,
  getProductsMetrics,
  getPromotionsMetrics,
  getStoresMetrics,
} from "../services/api";

export default {
  name: "StatsDisplay",
  data() {
    return {
      // Initialize metrics as an empty array
      metrics: [],
    };
  },
  mounted() {
    const metricsToFetch = [
      {
        id: "PRODUCT_COUNT",
        method: getProductsMetrics,
        label: this.$t("stats.products.label"),
      },
      {
        id: "PRICE_COUNT",
        method: getPricesMetrics,
        label: this.$t("stats.prices.label"),
      },
      {
        id: "PROMOTION_COUNT",
        method: getPromotionsMetrics,
        label: this.$t("stats.promotions.label"),
      },
      {
        id: "STORE_COUNT",
        method: getStoresMetrics,
        label: this.$t("stats.stores.label"),
      },
    ];

    // Generate fetch Promises for all the metrics
    const fetchPromises = metricsToFetch.map((metricInfo) => {
      return metricInfo.method().then((result) => {
        return {
          label: metricInfo.label,
          value: humanReadable.toHumanString(
            result.find((metric) => metric.id === metricInfo.id)?.Value
          ),
        };
      });
    });

    // Execute all the fetch Promises in parallel and update metrics
    Promise.all(fetchPromises)
      .then((allMetrics) => {
        this.metrics = allMetrics;
      })
      .catch(() => {
        // If any of the fetch Promises fail, display default values
        this.metrics = [
          { label: "Products", value: "256k" },
          { label: "Prices", value: "39M" },
          { label: "Promotions", value: "5.48M" },
          { label: "Stores", value: "3.95k" },
        ];
      });
  },
};
</script>
