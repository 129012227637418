<template>
  <a :href="url" @click="goToAppStore"
    ><img
      v-if="$i18n.locale === 'fr'"
      class="h-12"
      src="@/images/apple-app-store-badge-dark-fr.svg"
    />
    <img v-else class="h-12" src="@/images/apple-app-store-badge-dark-en.svg" />
  </a>
</template>

<script>
import posthog from "posthog-js";

export default {
  name: "AppStoreDownloadButton",
  props: ["url"],
  methods: {
    async goToAppStore(e) {
      e.preventDefault();
      try {
        // Event
        const eventName = "appstore_open";
        // PostHog
        posthog.capture(eventName);
        // Plausible
        if (window && window.plausible) {
          window.plausible(eventName);
        }
        // Bing
        if (window && window.uetq) {
          window.uetq = window.uetq || [];
          window.uetq.push("event", eventName);
        }
        // Facebook
        if (window && window.fbq) {
          window.fbq("track", "Lead");
        }
        // Reddit
        if (window && window.rdt) {
          window.rdt("track", "Lead");
        }
        // Wait for events to be sent
        await new Promise((resolve) => setTimeout(resolve, 750));
        // Google Analytics
        if (window && window.gtag) {
          window.gtag("event", "conversion", {
            send_to: "AW-11415020531/6UizCPOuzfwYEPPHjcMq",
            event_callback: () => {
              window.location.href = this.url;
            },
          });
        } else {
          window.location.href = this.url;
        }
      } catch (err) {
        window.location.href = this.url;
      }
    },
  },
};
</script>
