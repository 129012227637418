<template>
  <div
    class="fixed inset-0 bg-manypenny-primary-dark flex items-center justify-center"
  >
    <img class="w-32 h-32" src="../images/manypenny-logo.svg" />
  </div>
</template>

<script>
import { storeToRefs } from "pinia";
import posthog from "posthog-js";

import { subscribeToPlan } from "../services/api";

import { useOfferingStore } from "../stores/offering";
import { useUserStore } from "../stores/user";

export default {
  setup() {
    const offeringStore = useOfferingStore();
    const userStore = useUserStore();

    const { details } = storeToRefs(userStore);

    return {
      details,
      offeringStore,
    };
  },
  mounted() {
    if (this.details && this.details.UUID) {
      this.goToPlanPaymentPageURL();
    }
  },
  methods: {
    async goToPlanPaymentPageURL() {
      if (!this.details || !this.details.UUID) {
        this.$router.push("/");
      } else {
        try {
          // Event
          const eventName = "checkout_start";
          // PostHog
          posthog.capture(eventName);
          // Plausible
          if (window && window.plausible) {
            window.plausible(eventName);
          }
          // Get checkout session
          const checkoutSession = await subscribeToPlan(
            this.offeringStore?.packages?.$rc_monthly?.price?.id
          );
          // Bing
          if (window && window.uetq) {
            window.uetq = window.uetq || [];
            window.uetq.push("event", eventName, {
              revenue_value: checkoutSession.AmountSubtotal
                ? checkoutSession.AmountSubtotal / 100
                : 3.99,
              currency: checkoutSession.Currency || "CAD",
            });
          }
          // Facebook
          if (window && window.fbq) {
            window.fbq("track", "InitiateCheckout");
          }
          // Reddit
          if (window && window.rdt) {
            window.rdt("track", "InitiateCheckout");
          }
          // Wait for events to be sent
          await new Promise((resolve) => setTimeout(resolve, 750));
          // Google Analytics
          if (window && window.gtag) {
            window.gtag("event", "conversion", {
              send_to: "AW-11415020531/4S96CPmuzfwYEPPHjcMq",
              value: checkoutSession.AmountSubtotal
                ? checkoutSession.AmountSubtotal / 100
                : 3.99,
              currency: checkoutSession.Currency || "CAD",
              event_callback: function () {
                window.location.href = checkoutSession.Url;
              },
            });
          } else {
            window.location.href = checkoutSession.Url;
          }
        } catch (err) {
          this.$router.push("/");
        }
      }
    },
  },
  watch: {
    async details() {
      await this.goToPlanPaymentPageURL();
    },
  },
};
</script>
