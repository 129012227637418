import axios from "axios";
import { backOff } from "exponential-backoff";
import emailValidator from "email-validator";
import localforage from "localforage";
import set from "lodash.set";

import { getAccessToken } from "../plugins/auth0";

const baseURL = import.meta.env.VITE_API_URL;
const etagHeader = import.meta.env.VITE_API_ETAG_HEADER;
const newsletterOrigin = import.meta.env.VITE_NEWSLETTER_ORIGIN;
const positionHeader = import.meta.env.VITE_API_POSITION_HEADER;
const totalResultsHeader = import.meta.env.VITE_API_TOTAL_RESULTS_HEADER;

// Initialize localForage
const cache = localforage.createInstance({
  name: "manypenny",
  storeName: "api",
});

// Initialize axios with default config
const api = axios.create({
  baseURL,
  timeout: 15000,
});

// Helper function to generate a SHA-256 hash
const generateHash = async (input) => {
  const encoder = new TextEncoder();
  const data = encoder.encode(input);
  const hash = await crypto.subtle.digest("SHA-256", data);
  return Array.from(new Uint8Array(hash))
    .map((b) => b.toString(16).padStart(2, "0"))
    .join("");
};

// Helper function to generate a cache key based on the URL and its parameters
const generateCacheKey = async (config) =>
  generateHash(
    JSON.stringify({
      method: config.method,
      url: config.url,
      data: config.data || null,
      params: config.params || null,
    })
  );

// Interceptor to include Auth0 Bearer token
api.interceptors.request.use(
  async (config) => {
    try {
      const token = await getAccessToken();
      if (token) {
        set(config, "headers.Authorization", `Bearer ${token}`);
      }
    } catch (error) {
      console.error("Error adding Auth0 Bearer token:", error);
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.request.use(
  async (config) => {
    if (config.method !== "get") {
      return config;
    }
    // Generate cache key
    config.cacheKey = await generateCacheKey(config);
    // Check localForage for an ETag for this cache key
    const etag = await cache.getItem(`${config.cacheKey}:etag`);
    if (etag) {
      // Set the If-None-Match header
      config.headers["X-If-None-Match"] = etag;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor
api.interceptors.response.use(
  async (response) => {
    if (response.config.cacheKey) {
      const etag = response.headers[etagHeader];
      if (etag) {
        // Store the ETag and response data in localForage
        await cache.setItem(`${response.config.cacheKey}:etag`, etag);
        await cache.setItem(`${response.config.cacheKey}:data`, response.data);
      }
    }
    return response;
  },
  async (error) => {
    if (
      error.response &&
      error.response.status === 304 &&
      error.response.config.cacheKey
    ) {
      // If 304, retrieve cached data from localForage
      error.response.data = await cache.getItem(
        `${error.response.config.cacheKey}:data`
      );
      return Promise.resolve(error.response);
    }
    return Promise.reject(error);
  }
);

// Function to add the localized helper method
const addLocalizedMethod = (data) => {
  if (Array.isArray(data)) {
    // Add the helper method to each item in the array
    data.forEach((item) => addLocalizedMethod(item));
  } else if (typeof data === "object") {
    // Add the localized helper method to this object
    if (data.Localized) {
      data.localized = function (lang) {
        let localizedData = this.Localized?.find(
          (item) => item.Language === lang
        );

        if (!localizedData) {
          localizedData = this.Localized?.find(
            (item) => item.Language === "eng"
          );
        }

        if (!localizedData) {
          localizedData = this.Localized?.[0] || null;
        }

        return localizedData;
      };
    }

    // Recursively apply to all properties
    for (const key of Object.keys(data)) {
      addLocalizedMethod(data[key]);
    }
  }
};

// Response Interceptor to add helper methods
api.interceptors.response.use(
  (response) => {
    if (!response.data) {
      return response;
    }
    // Apply the localized helper method recursively
    addLocalizedMethod(response.data);
    // Add a method to get the integer-parsed value of the 'X-Total-Results' header
    response.data.getTotalResults = function () {
      const totalResults = response.headers[totalResultsHeader];
      return totalResults ? parseInt(totalResults, 10) : null;
    };
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// *

export const getHealthStatus = async () => {
  try {
    const response = await backOff(() => api.get("/health/status"));
    const position = response.headers[positionHeader];
    return {
      data: response.data,
      position,
    };
  } catch (error) {
    throw new Error(`Could not get Health Status: ${error}`);
  }
};

export const reverseGeocode = async (latitude, longitude) => {
  try {
    const response = await backOff(() =>
      api.get("/utilities/geocoding/reverse", {
        params: {
          p: `${longitude},${latitude}`,
        },
      })
    );
    return response.data;
  } catch (error) {
    throw new Error(`Error fetching reverse geocode: ${error}`);
  }
};

export const getPricesMetrics = async (id) => {
  try {
    const response = await backOff(() => api.get("/prices/metrics"));
    if (id) {
      return response.data.find((item) => item.id === id);
    }
    return response.data;
  } catch (error) {
    throw new Error(`Could not get Prices Metrics: ${error}`);
  }
};

export const getProductsMetrics = async (id) => {
  try {
    const response = await backOff(() => api.get("/products/metrics"));
    if (id) {
      return response.data.find((item) => item.id === id);
    }
    return response.data;
  } catch (error) {
    throw new Error(`Could not get Products Metrics: ${error}`);
  }
};

export const getPromotionsMetrics = async (id) => {
  try {
    const response = await backOff(() => api.get("/promotions/metrics"));
    if (id) {
      return response.data.find((item) => item.id === id);
    }
    return response.data;
  } catch (error) {
    throw new Error(`Could not get Promotions Metrics: ${error}`);
  }
};

export const getStoresMetrics = async (id) => {
  try {
    const response = await backOff(() => api.get("/stores/metrics"));
    if (id) {
      return response.data.find((item) => item.id === id);
    }
    return response.data;
  } catch (error) {
    throw new Error(`Could not get Stores Metrics: ${error}`);
  }
};

export const subscribeToNewsletter = async (emailAddress) => {
  try {
    if (!emailValidator.validate(emailAddress)) {
      throw new Error("Invalid email address");
    }
    const response = await backOff(
      () =>
        api.post("/utilities/newsletter/subscribe", {
          emailAddress,
          origin: newsletterOrigin,
        }),
      {
        numOfAttempts: 3,
      }
    );
    return response;
  } catch (error) {
    if (
      error.response &&
      (error.response.status === 409 || error.response.status === 422)
    ) {
      return error.response;
    }
    throw new Error(`Could not subscribe to newsletter: ${error}`);
  }
};

export const subscribeToPlan = async (priceId) => {
  try {
    const response = await backOff(() =>
      api.post("/users/self/subscribe", {
        CancelURL: `${window.location.origin}/subscribe/cancel`,
        Coupon: window?.Rewardful?.coupon?.id || null,
        PriceId: priceId,
        Referral: window?.Rewardful?.referral || null,
        SuccessURL: `${window.location.origin}/subscribe/success`,
      })
    );
    return response.data;
  } catch (error) {
    throw new Error(`Could not subscribe to plan: ${error}`);
  }
};

export const getBillingPortalSession = async () => {
  try {
    const response = await backOff(() =>
      api.post("/users/self/portal", {
        ReturnURL: `${window.location.origin}/account`,
      })
    );
    return response.data;
  } catch (error) {
    throw new Error(`Could not get Billing Portal Session: ${error}`);
  }
};

export const getMyDetails = async () => {
  try {
    const response = await backOff(() => api.get("/users/self"));
    return response.data;
  } catch (error) {
    throw new Error(`Could not get User Details: ${error}`);
  }
};

export const getSubscriberData = async () => {
  try {
    const response = await backOff(() => api.get("/users/self/subscriber"));
    return response.data;
  } catch (error) {
    throw new Error(`Could not get User Subscriber Data: ${error}`);
  }
};

export const updateMyDetails = async (details) => {
  try {
    const response = await backOff(() => api.patch("/users/self", details));
    return response.status === 204;
  } catch (error) {
    throw new Error(`Could not update User Details: ${error}`);
  }
};
