<template>
  <div
    class="mb-12 md:mb-20 w-full inline-flex flex-nowrap overflow-hidden [mask-image:_linear-gradient(to_right,transparent_0,_black_128px,_black_calc(100%-200px),transparent_100%)]"
  >
    <ul
      class="flex items-center justify-center md:justify-start [&_li]:mx-8 [&_img]:max-w-none animate-infinite-scroll"
    >
      <li v-for="chain in randomChains" :key="chain.id">
        <img
          class="w-36 h-12 grayscale object-contain"
          :src="chain.Logo.replace('logo.png', 'grayscale.png')"
          :alt="chain.localized(languageMappings[$i18n.locale.value])?.Name"
        />
      </li>
    </ul>
    <ul
      class="flex items-center justify-center md:justify-start [&_li]:mx-8 [&_img]:max-w-none animate-infinite-scroll"
      aria-hidden="true"
    >
      <li v-for="chain in randomChains" :key="chain.id">
        <img
          class="w-36 h-12 grayscale object-contain"
          :src="chain.Logo.replace('logo.png', 'grayscale.png')"
          :alt="chain.localized(languageMappings[$i18n.locale.value])?.Name"
        />
      </li>
    </ul>
  </div>
</template>

<script setup>
import { storeToRefs } from "pinia";

import { useDataStore } from "../stores/data";

import { mappings as languageMappings } from "../utils/language";
import { computed } from "vue";

const dataStore = useDataStore();
const { chains } = storeToRefs(dataStore);

function shuffle(array) {
  let currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex > 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
}

const randomChains = computed(() => shuffle(chains.value));
</script>
