import { defineStore } from "pinia";

import { getMyDetails, getSubscriberData } from "../services/api";

export const useUserStore = defineStore("user", {
  state: () => ({
    details: {},
    journey: null,
    subscriber: {},
  }),
  actions: {
    async fetchDetails() {
      const details = await getMyDetails();
      this.details = details;
    },
    async fetchSubscriberData() {
      const subscriber = await getSubscriberData();
      this.subscriber = subscriber;
    },
  },
});
