<template>
  <section class="border-t border-transparent dark:border-gray-800">
    <div class="py-12 md:py-20">
      <div class="max-w-6xl mx-auto px-4 sm:px-6">
        <!-- Section header -->
        <div class="max-w-3xl mx-auto text-center pb-12 md:pb-16">
          <h1 class="h2 font-red-hat-display mb-4">
            {{ $t("carousel.title") }}
          </h1>
          <p class="text-xl text-gray-600 dark:text-gray-400">
            {{ $t("carousel.subtitle") }}
          </p>
        </div>
      </div>

      <!-- Carousel built with Swiper.js [https://swiperjs.com/] -->
      <!-- * Custom styles in src/css/additional-styles/theme.scss -->
      <div class="carousel swiper-container">
        <div class="swiper-wrapper">
          <!-- Carousel items -->
          <div class="swiper-slide max-w-lg">
            <img
              class="transition-opacity duration-300"
              src="../images/carousel-photo-01-updated.jpg"
              width="540"
              height="460"
              :alt="$t('carousel.photos.one.alt')"
            />
            <div
              class="absolute inset-0 flex flex-col transition-opacity duration-300 translate-z-0"
            >
              <div class="absolute bottom-0 right-0 p-6">
                <span
                  class="text-xs font-medium text-center text-white py-2 px-3 rounded-full bg-gray-900 bg-opacity-50 hover:bg-opacity-100 transition duration-150 ease-in-out"
                  >{{ $t("carousel.photos.one.caption") }}</span
                >
              </div>
            </div>
          </div>
          <div class="swiper-slide max-w-lg">
            <img
              class="transition-opacity duration-300"
              src="../images/carousel-photo-02-updated.jpg"
              width="540"
              height="460"
              :alt="$t('carousel.photos.two.alt')"
            />
            <div
              class="absolute inset-0 flex flex-col transition-opacity duration-300 translate-z-0"
            >
              <div class="absolute bottom-0 right-0 p-6">
                <span
                  class="text-xs font-medium text-center text-white py-2 px-3 rounded-full bg-gray-900 bg-opacity-50 hover:bg-opacity-100 transition duration-150 ease-in-out"
                  >{{ $t("carousel.photos.two.caption") }}</span
                >
              </div>
            </div>
          </div>
          <div class="swiper-slide max-w-lg">
            <img
              class="transition-opacity duration-300"
              src="../images/carousel-photo-03-updated.jpg"
              width="540"
              height="460"
              :alt="$t('carousel.photos.three.alt')"
            />
            <div
              class="absolute inset-0 flex flex-col transition-opacity duration-300 translate-z-0"
            >
              <div class="absolute bottom-0 right-0 p-6">
                <span
                  class="text-xs font-medium text-center text-white py-2 px-3 rounded-full bg-gray-900 bg-opacity-50 hover:bg-opacity-100 transition duration-150 ease-in-out"
                  >{{ $t("carousel.photos.three.caption") }}</span
                >
              </div>
            </div>
          </div>
          <div class="swiper-slide max-w-lg">
            <img
              class="transition-opacity duration-300"
              src="../images/carousel-photo-04-updated.jpg"
              width="540"
              height="460"
              :alt="$t('carousel.photos.four.alt')"
            />
            <div
              class="absolute inset-0 flex flex-col transition-opacity duration-300 translate-z-0"
            >
              <div class="absolute bottom-0 right-0 p-6">
                <span
                  class="text-xs font-medium text-center text-white py-2 px-3 rounded-full bg-gray-900 bg-opacity-50 hover:bg-opacity-100 transition duration-150 ease-in-out"
                  >{{ $t("carousel.photos.four.caption") }}</span
                >
              </div>
            </div>
          </div>
          <div class="swiper-slide max-w-lg">
            <img
              class="transition-opacity duration-300"
              src="../images/carousel-photo-05-updated.jpg"
              width="540"
              height="460"
              :alt="$t('carousel.photos.five.alt')"
            />
            <div
              class="absolute inset-0 flex flex-col transition-opacity duration-300 translate-z-0"
            >
              <div class="absolute bottom-0 right-0 p-6">
                <span
                  class="text-xs font-medium text-center text-white py-2 px-3 rounded-full bg-gray-900 bg-opacity-50 hover:bg-opacity-100 transition duration-150 ease-in-out"
                  >{{ $t("carousel.photos.five.caption") }}</span
                >
              </div>
            </div>
          </div>
          <div class="swiper-slide max-w-lg">
            <img
              class="transition-opacity duration-300"
              src="../images/carousel-photo-06-updated.jpg"
              width="540"
              height="460"
              :alt="$t('carousel.photos.six.alt')"
            />
            <div
              class="absolute inset-0 flex flex-col transition-opacity duration-300 translate-z-0"
            >
              <div class="absolute bottom-0 right-0 p-6">
                <span
                  class="text-xs font-medium text-center text-white py-2 px-3 rounded-full bg-gray-900 bg-opacity-50 hover:bg-opacity-100 transition duration-150 ease-in-out"
                  >{{ $t("carousel.photos.six.caption") }}</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Arrows -->
      <div class="max-w-6xl mx-auto px-4 sm:px-6">
        <div class="flex items-center justify-between mt-12 md:mt-16">
          <button
            class="carousel-prev relative z-20 w-12 h-12 p-1 box-content flex items-center justify-center group bg-teal-500 hover:bg-teal-400 dark:bg-manypenny-secondary-dark dark:hover:bg-manypenny-secondary-light dark:hover:bg-opacity-25 shadow-xl transition duration-150 ease-in-out"
          >
            <span class="sr-only">{{ $t("carousel.buttons.previous") }}</span>
            <svg
              class="w-4 h-4 fill-current text-white dark:text-gray-400 group-hover:text-white dark:group-hover:text-manypenny-secondary-light transition duration-150 ease-in-out"
              viewBox="0 0 16 16"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M6.7 14.7l1.4-1.4L3.8 9H16V7H3.8l4.3-4.3-1.4-1.4L0 8z" />
            </svg>
          </button>
          <button
            class="carousel-next relative z-20 w-12 h-12 p-1 box-content flex items-center justify-center group bg-teal-500 hover:bg-teal-400 dark:bg-manypenny-secondary-dark dark:hover:bg-manypenny-secondary-light dark:hover:bg-opacity-25 shadow-xl transition duration-150 ease-in-out"
          >
            <span class="sr-only">{{ $t("carousel.buttons.next") }}</span>
            <svg
              class="w-4 h-4 fill-current text-white dark:text-gray-400 group-hover:text-white dark:group-hover:text-manypenny-secondary-light transition duration-150 ease-in-out"
              viewBox="0 0 16 16"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.3 14.7l-1.4-1.4L12.2 9H0V7h12.2L7.9 2.7l1.4-1.4L16 8z"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Swiper, { Autoplay, Navigation } from "swiper";

import "swiper/css";

Swiper.use([Autoplay, Navigation]);

export default {
  name: "ImageCarousel",
  mounted() {
    new Swiper(".carousel", {
      slidesPerView: "auto",
      grabCursor: true,
      loop: true,
      centeredSlides: true,
      initialSlide: 1,
      spaceBetween: 24,
      autoplay: {
        delay: 7000,
      },
      navigation: {
        nextEl: ".carousel-next",
        prevEl: ".carousel-prev",
      },
    });
  },
};
</script>
