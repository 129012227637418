import axios from "axios";
import { backOff } from "exponential-backoff";
import { v4 as uuid } from "uuid";

const baseURL = import.meta.env.VITE_REVENUECAT_API_URL;
const apiKey = import.meta.env.VITE_REVENUECAT_API_KEY;

const xPlatform = "stripe";

// Initialize axios with default config
const api = axios.create({
  baseURL,
  timeout: 15000,
});

api.interceptors.request.use(
  async (config) => {
    config.headers["Authorization"] = `Bearer ${apiKey}`;
    config.headers["X-Platform"] = xPlatform;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// *

export const getOfferings = async (subscriber, options) => {
  try {
    const response = await backOff(() =>
      api.get(`/subscribers/${subscriber || uuid()}/offerings`, options)
    );
    return response.data;
  } catch (error) {
    throw new Error(
      `Could not get Offerings for Subscriber ${
        subscriber || "anonymous"
      }: ${error}`
    );
  }
};

export const getSubscriber = async (subscriber, options) => {
  try {
    const response = await backOff(() =>
      api.get(`/subscribers/${subscriber}`, options)
    );
    return response.data;
  } catch (error) {
    throw new Error(`Could not get Subscriber ${subscriber}: ${error}`);
  }
};
