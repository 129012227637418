<template>
  <div
    class="fixed inset-0 bg-manypenny-primary-dark flex items-center justify-center"
  >
    <img class="w-32 h-32" src="../images/manypenny-logo.svg" />
  </div>
</template>

<script>
import { storeToRefs } from "pinia";

import { getBillingPortalSession } from "../services/api";
import { useUserStore } from "../stores/user";

export default {
  setup() {
    const userStore = useUserStore();

    const { details, subscriber } = storeToRefs(userStore);

    return {
      details,
      subscriber,
    };
  },
  mounted() {
    if (
      this.subscriber &&
      this.subscriber?.Entitlements &&
      Object.keys(this.subscriber?.Entitlements).length > 0 &&
      this.details &&
      this.details?.UUID
    ) {
      this.goToBillingPortalURL();
    }
  },
  methods: {
    async goToBillingPortalURL() {
      if (
        this.subscriber &&
        this.subscriber?.Entitlements &&
        Object.keys(this.subscriber?.Entitlements).length > 0 &&
        this.details &&
        this.details?.UUID
      ) {
        try {
          const billingPortalSession = await getBillingPortalSession();
          window.location.href = billingPortalSession.Url;
        } catch (err) {
          this.$router.push("/account");
        }
      } else {
        this.$router.push("/account");
      }
    },
  },
  watch: {
    async details() {
      if (
        this.subscriber &&
        this.subscriber?.Entitlements &&
        Object.keys(this.subscriber?.Entitlements).length > 0 &&
        this.details &&
        this.details?.UUID
      ) {
        await this.goToBillingPortalURL();
      }
    },
    async subscriber() {
      if (
        this.subscriber &&
        this.subscriber?.Entitlements &&
        Object.keys(this.subscriber?.Entitlements).length > 0 &&
        this.details &&
        this.details?.UUID
      ) {
        await this.goToBillingPortalURL();
      }
    },
  },
};
</script>
