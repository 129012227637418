<template>
  <div
    class="pointer-events-none fixed inset-x-0 bottom-0 sm:flex sm:justify-center sm:px-6 sm:pb-5 lg:px-8 z-50"
  >
    <div
      class="pointer-events-auto flex items-center justify-center gap-x-6 bg-manypenny-primary-light px-6 py-2.5 sm:rounded-xl sm:py-3"
    >
      <p
        class="text-sm leading-6 text-white text-center"
        v-if="$i18n.locale === 'fr'"
      >
        <span style="font-weight: bold">🌟 Bonne Nouvelle !</span>
        Many Penny est maintenant
        <span style="font-weight: bold">GRATUIT!</span><br />
        <router-link
          to="/account"
          style="text-decoration: underline; font-weight: bold"
          >Plongez dans les économies intelligentes</router-link
        >
        dès aujourd'hui.
      </p>
      <p class="text-sm leading-6 text-white text-center" v-else>
        <span style="font-weight: bold">🌟 Good News!</span>
        Many Penny is now
        <span style="font-weight: bold">FREE!</span><br />
        <router-link
          to="/account"
          style="text-decoration: underline; font-weight: bold"
          >Dive into smarter savings</router-link
        >
        today.
      </p>
    </div>
  </div>
</template>
