import axios from "axios";
import { backOff } from "exponential-backoff";

const baseURL = import.meta.env.VITE_ASSETS_URL;

// Initialize axios with default config
const api = axios.create({
  baseURL,
  timeout: 15000,
});

// Function to add the localized helper method
const addLocalizedMethod = (data) => {
  if (Array.isArray(data)) {
    // Add the helper method to each item in the array
    data.forEach((item) => addLocalizedMethod(item));
  } else if (typeof data === "object") {
    // Add the localized helper method to this object
    if (data.Localized) {
      data.localized = function (lang) {
        let localizedData = this.Localized?.find(
          (item) => item.Language === lang
        );

        if (!localizedData) {
          localizedData = this.Localized?.find(
            (item) => item.Language === "eng"
          );
        }

        if (!localizedData) {
          localizedData = this.Localized?.[0] || null;
        }

        return localizedData;
      };
    }

    // Recursively apply to all properties
    for (const key of Object.keys(data)) {
      addLocalizedMethod(data[key]);
    }
  }
};

// Response Interceptor to add helper methods
api.interceptors.response.use(
  (response) => {
    if (!response.data) {
      return response;
    }
    // Apply the localized helper method recursively
    addLocalizedMethod(response.data);
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// *

export const getChainsManifest = async () => {
  try {
    const response = await backOff(() =>
      api.get("/sandbox/chains/manifest.json")
    );
    return response.data;
  } catch (error) {
    throw new Error(`Could not get Chains Manifest: ${error}`);
  }
};

export const getStore = async (id) => {
  try {
    const response = await api.get(`/sandbox/stores/${id}.json`);
    return response.data;
  } catch (error) {
    throw new Error(`Could not get Stores Manifest: ${error}`);
  }
};

export const getStoresManifest = async () => {
  try {
    const response = await backOff(() =>
      api.get("/sandbox/stores/manifest.geojson")
    );
    return response.data;
  } catch (error) {
    throw new Error(`Could not get Stores Manifest: ${error}`);
  }
};
