export default {
  account: {
    button: {
      text: "Enregistrer",
    },
    deletion: {
      label: "Supprimer mon compte",
      link: "Cliquez ici pour demander la suppression de votre compte",
      success: "Votre demande de suppression de compte a été soumise.",
      error: "Oups, quelque chose s'est mal passé. Veuillez réessayer.",
      warning:
        "Veuillez confirmer que vous comprenez que votre compte et toutes les données associées seront définitivement supprimés.",
      button: {
        text: "Envoyer la demande",
      },
      confirmation: {
        label:
          "Je comprends que mon compte et toutes les données associées seront définitivement supprimés.",
      },
      reason: {
        placeholder:
          "Nous cherchons toujours à nous améliorer. Si cela ne vous dérange pas de partager, dites-nous pourquoi vous partez.",
        label: "Raison de la demande de suppression de compte",
      },
      title: "Demander la suppression du compte",
      intro:
        "Nous sommes désolés de vous voir partir {name}. Votre vie privée et la sécurité de vos données sont importantes pour nous. Si vous souhaitez supprimer votre compte et retirer toutes les données associées, veuillez remplir ce formulaire. Nous traiterons votre demande aussi rapidement que possible, en veillant à ce que toutes vos informations soient retirées de manière sécurisée et complète de notre système.",
    },
    fullName: {
      cantUpdate:
        "Votre nom est géré par votre fournisseur d'identité (Apple ou Google). Pour changer votre nom, veuillez mettre à jour votre profil avec votre fournisseur d'identité.",
      label: "Nom complet",
      placeholder: "Entrez votre nom complet",
    },
    store: {
      app_store: {
        instructions:
          "Pour gérer votre abonnement, ouvrez l'application App Store sur votre appareil et touchez votre icône de profil dans le coin supérieur droit. Ensuite, touchez Abonnements. Dans la liste des abonnements, trouvez et touchez Many Penny. Là, vous pouvez changer ou annuler votre abonnement.",
      },
      play_store: {
        instructions:
          "Pour gérer votre abonnement, ouvrez l'application Google Play Store sur votre appareil et touchez l'icône ☰ (trois lignes horizontales). Ensuite, touchez Abonnements. Dans la liste des abonnements, trouvez et touchez Many Penny. Là, vous pouvez changer ou annuler votre abonnement.",
      },
      promotional: {
        instructions:
          "Félicitations ! Vous avez reçu un abonnement promotionnel gratuit à Many Penny. Profitez de l'expérience premium offerte. Pas besoin de gérer quoi que ce soit, plongez-vous dedans et amusez-vous !",
      },
      stripe: {
        link: "Cliquez ici pour gérer votre abonnement",
      },
    },
    subscription: {
      label: "Votre abonnement",
      text: "Vous êtes abonné(e) au forfait {plan} via {store}",
      expiry:
        "Votre abonnement est prévu pour expirer ou se renouveler le {date} à {time}",
    },
    upgrade: {
      text: {
        line1:
          "Vous êtes actuellement inscrit à la version gratuite de Many Penny.",
        line2:
          "Pour débloquer toutes les fonctionnalités de Many Penny, vous devez mettre à niveau votre abonnement.",
      },
    },
  },
  carousel: {
    title: "Toujours à jour, jamais dépassé",
    subtitle:
      "Les prix des produits alimentaires peuvent varier chaque jour. Avec Many Penny, vous bénéficiez des dernières tendances de prix et des meilleures offres, sans le tracas de suivre manuellement. Faites-nous confiance pour vous tenir au courant, afin que vous puissiez vous concentrer sur des choix judicieux.",
    photos: {
      one: {
        alt: "Homme dans une allée d'épicerie regardant des produits de nettoyage.",
        caption: "Prendre des décisions éclairées à chaque regard.",
      },
      two: {
        alt: "Femme vérifiant son téléphone tout en tenant un article d'épicerie.",
        caption: "Renforcez vos achats avec des informations en temps réel.",
      },
      three: {
        alt: "Homme naviguant sur son téléphone dans la section des légumes.",
        caption: "Naviguez facilement parmi les meilleures offres.",
      },
      four: {
        alt: "Femme élégante vérifiant son téléphone dans une épicerie.",
        caption: "L'élégance rencontre l'efficacité à chaque course.",
      },
      five: {
        alt: "Homme d'affaires examinant une bouteille de boisson dans une allée d'épicerie.",
        caption: "Choisir judicieusement, même en déplacement.",
      },
      six: {
        alt: "Homme lisant les détails du produit dans une épicerie avec des étiquettes de prix visibles.",
        caption: "Chaque sou compte; chaque détail est important.",
      },
    },
    buttons: {
      next: "Suivant",
      previous: "Précédent",
    },
  },
  cta: {
    title: "Abonnez-vous à notre infolettre et recevez les dernières nouvelles",
    form: {
      button: {
        text: "S'abonner",
      },
      email: {
        label: "Adresse e-mail",
        placeholder: "Entrez votre e-mail",
      },
      messages: {
        conflict: "Vous êtes déjà abonné à notre newsletter. Merci !",
        error: "Veuillez entrer une adresse e-mail valide.",
        success: "Merci de vous être abonné ! Nous vous contacterons bientôt.",
        unknown: "Oups, une erreur s'est produite. Veuillez réessayer.",
      },
    },
  },
  dropdown: {
    logout: {
      text: "Se déconnecter",
    },
  },
  footer: {
    attributions: {
      apple:
        "Apple, le logo Apple et iPhone sont des marques déposées d'Apple Inc., enregistrées aux États-Unis et dans d'autres pays. App Store est une marque de service d'Apple Inc., enregistrée aux États-Unis et dans d'autres pays.",
      google:
        "Google Play et le logo Google Play sont des marques déposées de Google LLC.",
      others:
        "D'autres noms de produits et de sociétés mentionnés ici peuvent être des marques déposées de leurs sociétés respectives.",
    },
    built: {
      pre: "Conçu avec",
      post: "à Victoria, C.-B., Canada.",
    },
    contact: {
      header: "Contact",
      affiliates: {
        address: "https://affiliates.manypenny.ca",
        label: "Affiliés",
      },
      email: {
        address: "bonjour{'@'}manypenny.ca",
        label: "bonjour(a)manypenny.ca",
      },
      press: {
        address: "https://newsroom.manypenny.ca/fr",
        label: "Presse",
      },
      phone: {
        uri: "sms:+17784002084",
        display: "(778) 400-2084",
      },
    },
    copyrights: {
      note: "Tous droits réservés.",
    },
    what: {
      header: "Ce que nous faisons",
      blog: "Blog",
      plans: "Plans et caractéristiques",
      chrome: {
        href: "https://chromewebstore.google.com/detail/many-penny/lnlbedbfpggddbdebdlknbjlibgcande",
        display: "Extension Google Chrome",
      },
    },
    support: {
      header: "Support",
      hc: "Centre d'aide",
    },
    status: {
      header: "Statut",
      status: "Statut du système",
    },
    terms: {
      acceptable: "Utilisation Acceptable",
      consent: "Préférences de Consentement",
      cookie: "Cookies",
      disclaimer: "Avertissement",
      donotsell: "Ne Pas Vendre ou Partager Mes Informations Personnelles",
      eula: "CLUF",
      privacy: "Confidentialité",
      terms: "Conditions",
    },
  },
  header: {
    button: {
      text: "Commencer",
      authenticated: "Mon compte",
    },
  },
  hero: {
    title: "Économisez au delà des circulaires",
    subtitle:
      "Many Penny est votre vérificateur de prix de poche pour des comparaisons en temps réel – oubliez les circulaires, scannez et économisez !",
    features: {
      one: "Comparaisons de prix instantanées",
      two: "Chasse aux offres sans effort",
      three: "Alertes de baisse de prix",
    },
  },
  logo: {
    alt: "Logo Many Penny",
  },
  mockup: {
    alt: "Capture d'écran de l'application Many Penny",
    home: "Accueil",
    yourFavouriteStores: "Vos magasins préférés",
    location: "Victoria, C.-B., Canada",
    seeAll: "Voir tout",
    search: "Rechercher",
    groceryList: "Liste de courses",
    profile: "Profil",
    stores: {
      first: {
        chain: "Thrifty Foods",
        address: "1590 Fairfield Rd",
        distance: "1,2 km de distance",
      },
      second: {
        chain: "Real Canadian Superstore",
        address: "172 Wilson St",
        distance: "1,4 km de distance",
      },
      third: {
        chain: "Walmart",
        address: "2635 Quadra St",
        distance: "1,8 km de distance",
      },
    },
    distance: "{distance} km de distance",
    allCategories: "All categories",
    categories: {
      baby: "Articles pour bébé",
      beauty: "Beauté",
      breadBakery: "Pain et boulangerie",
      clothingShoes: "Vêtements, chaussures et accessoires",
      frozenFood: "Aliments surgelés",
      fruitsVegetables: "Fruits et légumes",
      health: "Santé",
      homeKitchen: "Maison, cuisine et extérieur",
      personalCare: "Soins personnels",
      pets: "Animaux de compagnie",
      seasonalHolidays: "Saisonnier et vacances",
      snacksCandy: "Collations et bonbons",
    },
  },
  plans: {
    title: "Libérez vos achats intelligents avec Many Penny",
    subtitle:
      "Commencez avec nos fonctionnalités de base gratuitement, et lorsque vous êtes prêt, débloquez une expérience d'achat complète avec notre plan Standard.",
    manage: {
      button: {
        text: "Gérer mon abonnement",
      },
    },
    current: {
      text: "Votre plan actuel",
    },
    plans: {
      all: {
        features: {
          header: "Tous les plans comprennent :",
          one: "Expérience d'achat unifiée : Adieu la jonglerie entre les sites ou les applications",
          two: "Liste de produits constamment mise à jour",
          three: "Interface intuitive pour un parcours fluide",
          four: "Gestion de compte sécurisée et fiable",
        },
      },
      free: {
        name: "Gratuit",
        period: "toujours",
        features: {
          one: "Recherche de produit pratique, y compris le scan de codes-barres",
          two: "Parcourez les produits par catégorie",
          three: "Catégorisation uniforme des produits à travers les chaînes",
          four: "Créez vos listes de courses",
          five: "Sélectionnez et enregistrez vos magasins préférés",
        },
        button: {
          text: "Commencer",
        },
      },
      standard: {
        name: "Standard",
        period: "mensuellement",
        trial: "essai de 2 semaines",
        features: {
          one: "Voir et comparer les prix des produits entre les magasins",
          two: "Accédez aux tendances historiques des prix",
          three: "Itinéraire d'achat automatique pour les meilleures offres",
          four: "Recherche continue des meilleures offres en fonction de vos listes",
        },
        button: {
          text: "Souscrire",
          upgrade: "Passer à l'abonnement supérieur",
        },
      },
    },
  },
  stats: {
    products: {
      label: "Produits",
    },
    prices: {
      label: "Prix",
    },
    promotions: {
      label: "Promotions",
    },
    stores: {
      label: "Magasins",
    },
  },
  faqs: {
    title: "Questions Fréquentes",
    one: {
      question: "Qu'est-ce que Many Penny ?",
      answer:
        "Many Penny est une application innovante de comparaison de prix et d'alerte qui vise à transformer votre expérience de magasinage. L'application fournit des comparaisons de prix en temps réel entre les principales chaînes canadiennes, des alertes de baisse de prix, des listes de courses personnalisées, un suivi complet des prix, et plus encore.",
    },
    two: {
      question: "Comment fonctionne Many Penny ?",
      answer:
        "Après avoir créé votre compte, ajoutez simplement des articles à votre liste de courses, et nous comparerons instantanément les prix dans divers magasins, vous faisant gagner du temps et de l'argent. Si une baisse de prix se produit sur un article que vous avez favorisé, nous vous enverrons immédiatement une notification.",
    },
    three: {
      question: "Pourquoi choisir Many Penny ?",
      answer:
        "Nous offrons un service qui va au-delà des circulaires traditionnelles et des applications de magasin unique. Notre base de données étendue fournit des prix à jour, des promotions en cours et des données historiques sur les prix. Nous offrons des expériences de magasinage personnalisées, et nous accordons la priorité à la confidentialité des utilisateurs.",
    },
    four: {
      question: "En quoi le modèle de revenu de Many Penny est-il différent ?",
      answer:
        "Contrairement à la plupart des applications qui dépendent des liens d'affiliation de produits pour générer des revenus, nous fonctionnons sur un modèle de revenus basé sur l'abonnement. Cette approche nous permet de préserver notre indépendance et de vous fournir des informations de prix impartiales.",
    },
    five: {
      question: "Comment puis-je m'abonner à Many Penny ?",
      answer:
        "Une fois inscrit chez Many Penny, vous bénéficierez d'une période d'essai gratuite d'un mois. Après cette période, si vous décidez de continuer avec nos services, vous pouvez vous abonner par divers canaux. Les abonnements peuvent être effectués directement sur notre site web, ou via des places de marché d'applications comme l'App Store pour les utilisateurs iOS et le Google Play Store pour les utilisateurs Android. Profitez de la polyvalence et de la commodité de gérer votre abonnement là où cela vous convient le mieux.",
    },
    six: {
      question: "Si j'ai besoin d'aide, comment puis-je contacter Many Penny ?",
      answer:
        "Si vous avez besoin d'aide ou de plus d'informations, veuillez contacter notre équipe de support à support(a)manypenny.ca. Nous nous engageons à garantir que votre expérience de magasinage soit aussi fluide et agréable que possible.",
    },
  },
};
