<template>
  <div
    class="fixed inset-0 bg-manypenny-primary-dark flex items-center justify-center"
  >
    <img class="w-32 h-32" src="../images/manypenny-logo.svg" />
  </div>
</template>

<script>
import { useAuth0 } from "@auth0/auth0-vue";

import { useUserStore } from "../stores/user";

export default {
  name: "SubscribeCancel",
  setup() {
    const { isAuthenticated } = useAuth0();
    const userStore = useUserStore();

    return {
      isAuthenticated,
      userStore,
    };
  },
  mounted() {
    if (this.isAuthenticated) {
      this.setUserJourney();
      this.redirectToAccount();
    }
  },
  methods: {
    redirectToAccount() {
      this.$router.push("/account");
    },
    setUserJourney() {
      this.userStore.journey = "subscribe/cancel";
    },
  },
  watch: {
    isAuthenticated() {
      this.setUserJourney();
      this.redirectToAccount();
    },
  },
};
</script>
