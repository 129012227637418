import { defineStore } from "pinia";

export const useGeolocationStore = defineStore("geolocation", {
  state: () => ({
    mapCenter: [-106.346771, 56.130366], // Canada as default
    mapZoom: 2,
    reverse: {},
    userPosition: [],
  }),
});
