<template>
  <div class="flex flex-col min-h-screen overflow-hidden">
    <!-- Site header -->
    <AccountHeader />

    <!-- Page content -->
    <main class="grow">
      <section class="mb-20">
        <div class="max-w-6xl mx-auto px-4 sm:px-6">
          <div class="pt-32 pb-10 md:pt-40 md:pb-16">
            <form @submit.prevent="save">
              <div class="flex">
                <div
                  class="w-1/4 h-12 text-lg font-bold text-right mr-5 text-right pt-2"
                >
                  {{ $t("account.fullName.label") }}
                </div>
                <div class="w-2/4">
                  <input
                    type="text"
                    class="form-input text-gray-100 w-full mb-2 sm:mb-0 sm:mr-2"
                    :placeholder="$t('account.fullName.placeholder')"
                    :aria-label="$t('account.fullName.label')"
                    :value="details?.Name"
                    @input="followFullName"
                    :disabled="!canUpdateName"
                  />
                  <div class="mt-2 text-gray-400" v-if="!canUpdateName">
                    {{ $t("account.fullName.cantUpdate") }}
                  </div>
                </div>
              </div>
              <div class="flex mt-5" v-if="canUpdateName">
                <div
                  class="w-1/4 text-lg font-bold text-right mr-5 text-right pt-2"
                ></div>
                <div class="w-2/4">
                  <button
                    type="submit"
                    class="btn-sm text-white bg-manypenny-primary-light hover:bg-manypenny-primary-dark shrink-0"
                  >
                    {{ $t("account.button.text") }}
                  </button>
                </div>
              </div>
            </form>
            <!-- <div class="flex mt-10">
              <div class="w-1/4 text-lg font-bold text-right mr-5 text-right">
                {{ $t("account.subscription.label") }}
              </div>
              <div
                class="w-2/4 pt-0.5"
                v-if="
                  isAuthenticated &&
                  subscriber &&
                  subscriber?.Entitlements &&
                  Object.keys(subscriber?.Entitlements).length > 0 &&
                  Object.keys(subscriber?.Entitlements).includes('Standard') &&
                  subscriber?.Entitlements.Standard?.ExpiresDate &&
                  subscriber?.Entitlements.Standard?.ExpiresDate.length &&
                  new Date(subscriber?.Entitlements.Standard?.ExpiresDate) >
                    new Date()
                "
              >
                {{
                  $t("account.subscription.text", {
                    plan: Object.keys(subscriber?.Entitlements)[0],
                    store: storeName,
                  })
                }}
                <div
                  class="mt-1 text-gray-400"
                  v-if="
                    ['app_store', 'play_store', 'promotional'].includes(
                      storeCode
                    )
                  "
                >
                  {{ $t(`account.store.${storeCode}.instructions`) }}
                </div>
                <div
                  class="mt-1 text-gray-400"
                  v-else-if="storeCode === 'stripe'"
                >
                  <router-link to="/subscription/portal">
                    {{ $t(`account.store.${storeCode}.link`) }}
                  </router-link>
                </div>
                <div class="mt-3 text-gray-400 text-sm">
                  {{
                    $t("account.subscription.expiry", {
                      date: expiryDate.toLocaleDateString(),
                      time: expiryDate.toLocaleTimeString(),
                    })
                  }}
                </div>
              </div>
              <div class="w-2/4 pt-0.5" v-else>
                <div>
                  {{ $t("account.upgrade.text.line1") }}<br />
                  {{ $t("account.upgrade.text.line2") }}
                </div>
                <router-link
                  class="btn-sm text-white bg-manypenny-primary-light hover:bg-manypenny-primary-dark mt-5"
                  to="/subscribe"
                >
                  {{ $t("plans.plans.standard.button.upgrade") }}
                </router-link>
              </div>
            </div> -->
            <div class="flex mt-16">
              <div class="w-1/4 mr-5">&nbsp;</div>
              <div
                class="w-2/4 flex flex-row mb:flex-col space-x-4 mb:space-y-4 justify-start"
              >
                <AppStoreDownloadButton
                  v-if="(OSName && !OSName.startsWith('Android')) || !OSName"
                  url="https://apps.apple.com/ca/app/many-penny/id6444689768"
                />
                <PlayStoreDownloadButton
                  v-if="(OSName && !OSName.startsWith('iOS')) || !OSName"
                  url="https://play.google.com/store/apps/details?id=ca.manypenny"
                />
              </div>
            </div>
            <div class="flex mt-20 text-gray-500" v-if="isAuthenticated">
              <div
                class="w-1/4 h-12 text-lg font-bold text-right mr-5 text-right"
              >
                {{ $t("account.deletion.label") }}
              </div>
              <div class="w-2/4 h-12 pt-0.5">
                <router-link to="/account/deletion">
                  {{ $t(`account.deletion.link`) }}
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>

    <!-- Site footer -->
    <PageFooter />
  </div>
</template>

<script>
import { useAuth0 } from "@auth0/auth0-vue";
import { storeToRefs } from "pinia";
import UAParser from "ua-parser-js";
import { toast } from "vue3-toastify";

import { updateMyDetails } from "../services/api";
import { useUserStore } from "../stores/user";

import AccountHeader from "../partials/AccountHeader.vue";
import AppStoreDownloadButton from "../components/AppStoreDownloadButton.vue";
import PageFooter from "../partials/PageFooter.vue";
import PlayStoreDownloadButton from "../components/PlayStoreDownloadButton.vue";

const parser = new UAParser();
let osName = "";

try {
  osName = parser.getOS().name;
} catch (err) {
  console.log("Couldn't determine OS name");
}

export default {
  name: "AccountPage",
  components: {
    AccountHeader,
    AppStoreDownloadButton,
    PageFooter,
    PlayStoreDownloadButton,
  },
  setup() {
    const { isAuthenticated } = useAuth0();
    const userStore = useUserStore();

    const { details, subscriber } = storeToRefs(userStore);

    return {
      details,
      fullName: details?.Name,
      isAuthenticated,
      OSName: osName,
      subscriber,
      userStore,
    };
  },
  mounted() {
    if (this.userStore.journey) {
      switch (this.userStore.journey) {
        case "subscribe/success":
          this.showSubscribeSuccessMessage();
          break;
        case "subscribe/cancel":
          this.showSubscribeCancelMessage();
          break;
      }
      this.userStore.journey = null;
    }
  },
  computed: {
    expiryDate() {
      return new Date(
        this.subscriber?.Entitlements[
          Object.keys(this.subscriber?.Entitlements)[0]
        ].ExpiresDate
      );
    },
    canUpdateName() {
      return (
        String(this.details?.UserId).startsWith("auth0|") ||
        String(this.details?.UserId).startsWith("email|")
      );
    },
    storeCode() {
      return Object.values(this.subscriber?.Subscriptions)[0]?.Store;
    },
    storeName() {
      switch (this.storeCode) {
        case "app_store":
          return "the Apple App Store";
        case "mac_app_store":
          return "the Mac App Store";
        case "play_store":
          return "the Google Play Store";
        case "amazon":
          return "the Amazon App Store";
        case "stripe":
          return "Stripe";
        case "promotional":
          return "a Promotional Offer";
        default:
          return "Unknown";
      }
    },
  },
  methods: {
    followFullName(event) {
      const value = event.target.value;
      this.fullName = value;
    },
    async save() {
      try {
        await updateMyDetails({
          Name: this.fullName,
        });
        await this.userStore.fetchDetails();
      } catch (err) {
        console.error(err);
      }
    },
    showSubscribeCancelMessage() {
      toast.error(this.$t("subscribe.cancel"), {
        autoClose: 5000,
      });
    },
    showSubscribeSuccessMessage() {
      toast.success(this.$t("subscribe.success"), {
        autoClose: 5000,
      });
    },
  },
};
</script>
